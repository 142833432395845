import React, { FunctionComponent } from "react"
import { PageProps } from "@src-types/page"
import { useIndexPageData } from "@hooks/use-index-page-data"
import { QuestionsAnsweredPage } from "@features/questions-answered/components/questions-answered-page/questions-answered-page"
import { Page } from "@components/page/page"
import { PageBreadcrumbs } from "@core/breadcrumbs"
import { qasIndexConfig } from "@features/questions-answered/qas-index-config"
import { PageOpenGraphImage } from "@core/constants"

export const QuestionsAnswered: FunctionComponent<PageProps> = ({
  data,
  ...props
}) => {
  const { header, featuredResources, metadata } =
    useIndexPageData("questions-answered")

  return (
    <Page
      {...props}
      metadata={{
        ...metadata,
        openGraphImage: PageOpenGraphImage.Qas
      }}
      pageOptions={{
        headerVariant: "cream",
        breadcrumbs: PageBreadcrumbs.QuestionsAnswered,
        defaultSearchIndex: qasIndexConfig.defaultSearchIndex
      }}
    >
      <QuestionsAnsweredPage
        pageHeader={header}
        featuredList={featuredResources}
      />
    </Page>
  )
}

export default QuestionsAnswered
