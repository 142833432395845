import React, { FunctionComponent, Fragment } from "react"
import { IndexPageHeader } from "../../../../types/page"
import { IndexHeader } from "../../../../components/index-header/index-header"
import { Resource } from "../../../../types/resource"
import { FeaturedResources } from "../../../../components/featured-resources/featured-resources"
import { SearchResults } from "../../../search/components/search-results/search-results"
import { qasIndexConfig } from "../../qas-index-config"
import { getFilteredIndexes } from "../../../search/search-helpers"

export interface QuestionsAnsweredPageProps {
  pageHeader: IndexPageHeader
  featuredList: Resource[]
}

const { filters, searchConfig, indexes } = qasIndexConfig

export const QuestionsAnsweredPage: FunctionComponent<
  QuestionsAnsweredPageProps
> = ({ featuredList, pageHeader }) => (
  <Fragment>
    <IndexHeader header={pageHeader} />
    <FeaturedResources
      shouldUpdateList
      pageKey="questions-answered"
      labelId="featured-questions"
      list={featuredList}
      type="question"
    />
    <SearchResults
      filters={filters}
      config={searchConfig}
      variant="list"
      indexes={indexes ? getFilteredIndexes(indexes) : undefined}
    />
  </Fragment>
)
