import { IndexPageConfig } from "../../../gatsby/config/create-pages/types"
import { PathPrefix } from "../../core/constants"

export const qasIndexConfig: IndexPageConfig = {
  templatePath:
    "./src/features/questions-answered/templates/questions-answered-template.tsx",
  pagePath: PathPrefix.QuestionsAnswered,
  searchConfig: { filters: "type:qa" },
  attributes: ["type", "time", "title", "url"],
  indexes: ["newest", "oldest", "alphabetical"],
  filters: [
    {
      attribute: "fields.primaryTopic.fields.name",
      title: "search-filter-topics"
    },
    {
      attribute: "fields.teachers.fields.shortName",
      title: "search-filter-teachers"
    },
    {
      attribute: "fields.primaryScriptureReference.start.book",
      title: "search-filter-scripture"
    }
  ],
  defaultSearchIndex: "newest"
}
